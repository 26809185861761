
.c-feature {
	position: relative;

	padding: 1rem 2rem;
	margin: 0;

	text-align: center;

	// Ensure that content is overlaid on top of the background
	> * {
		position: relative;
		z-index: 100;
	}

	&--lozenge-bg {
		&::before {
			content: '';

			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		
			background: $color-white;
		
			transform: skew(-24deg);
		}
	}

	&__title {
		font-weight: 700;
		font-size: 1.5rem;
		margin-bottom: 0.5rem;
	}
	
	&__icon {
		width: 100px;
		height: 100px;
	
		margin: 0 auto;
		margin-bottom: 1rem;

		> svg {
			width: 100%;
			height: auto;
		}
	}
}
