
$body-width: 70rem;
$row-padding: 2rem;

// Colors and similar

$color-primary: #F4DC00;
$color-primary-darker: #e9bc16;
$color-secondary: $color-primary;

$color-accent-yellow: $color-primary;
$color-accent-red: #ff0018;
$color-accent-orange: #ff9831;
$color-accent-green: #4efc5d;
$color-accent-blue: #00e4ff;

$color-text-red: mix($color-accent-red, black, 50%);
$color-text-orange: mix($color-accent-orange, black, 50%);
$color-text-green: mix($color-accent-green, black, 50%);
$color-text-blue: mix($color-accent-blue, black, 50%);

$color-gray: #636355;

$color-gray-90: mix($color-gray, white, 20%);
$color-gray-80: mix($color-gray, white, 40%);
$color-gray-70: mix($color-gray, white, 60%);
$color-gray-60: mix($color-gray, white, 80%);
$color-gray-50: $color-gray;
$color-gray-40: mix($color-gray, black, 80%);
$color-gray-30: mix($color-gray, black, 60%);
$color-gray-20: mix($color-gray, black, 40%);
$color-gray-10: mix($color-gray, black, 20%);

$color-tan: #f5f1e9;

$body-color: white;

$color-white: white;
$color-black: black;

$divider-line: 2px dashed $color-gray-70;

// Responsive breakpoints

$breakpoint-small: 1300px;
$breakpoint-mobile: 980px;
$breakpoint-tiny: 600px;

@mixin divider-line {
	border-top: $divider-line;
}
