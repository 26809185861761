
.c-sector-list {

	&__title {
		font-size: 2.25rem;
		line-height: 4rem;
		vertical-align: top;

		font-weight: 900;

		> svg {
			display: inline-block;
			vertical-align: top;
		}
	}

	&__sector {
		position: relative;

		margin-top: 2rem;

		background: $color-tan;

		&--has-image {
			display: grid;
			grid-template-columns: 20rem 1fr;
			grid-column-gap: 1rem;
		}


		&-content {
			padding: 1rem;
		}

		&-button {
			position: absolute;

			right: 2rem;
			bottom: -1rem;

			padding-left: 2rem;
			padding-right: 2rem;

			z-index: 1000;

			cursor: pointer;
		}

		img {
			display: block;

			height: 100%;
			width: 100%;

			object-fit: cover;
		}
	}

}

@media( max-width: $breakpoint-small ) {
	.c-sector-list {
		.u-clip::before,
		.u-clip::after {
			display: none;
		}

		&__sector {
			display: block;

			img {
				display: none;
			}
		}
	}
}
