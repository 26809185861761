
// The auto-prefixer doesn't apply properly to -webkit-mask-image for some reason, so we're setting it up manually here.
// This is just used for adding a subtle fade effect to the left or right sides of the carousel, if there's more information
// there to reveal to the user.
@mixin scrollMask($left, $right) {
	$transparent: rgba(0, 0, 0, 0.25);
	$size: 3.5%;

	@if( $left and $right ) {
		$mask: linear-gradient(to left, $transparent, black $size, black 100% - $size + 1%, $transparent);

		mask-image: $mask;
		-webkit-mask-image: $mask;
	}
	@else if( $left ) {
		$mask: linear-gradient(to right, black 100% - $size, $transparent);

		mask-image: $mask;
		-webkit-mask-image: $mask;
	}
	@else if( $right ) {
		$mask: linear-gradient(to left, black 100% - $size, $transparent);

		mask-image: $mask;
		-webkit-mask-image: $mask;
	}
	@else {
		mask-image: unset !important;
		-webkit-mask-image: unset !important;
	}
}

.c-product-list {
	--row-count: 3;
	--column-count: 1;

	display: grid;
	grid-template-columns: 1fr minmax(auto, $body-width) 1fr;
	grid-template-rows: auto auto auto 1fr;

	grid-template-areas:
		"gutter1 title gutter2"
		"gutter1 content gutter2"
		"divider divider gutter2"
		"gutter3 products gutter2";

	&--tan-bg {
		background: $color-tan;
	}

	&__title {
		grid-area: title;

		padding: 1rem $row-padding;
		margin: 0;
	}

	&__divider {
		@include divider-line;

		grid-area: divider;

		margin: 0.5rem 0;
		margin-inline: unset; // Overwrite default <hr> behavior
	}

	&__text {
		grid-area: content;

		padding: 1rem $row-padding;

		p {
			margin: 0;
		}

		> * + * {
			margin-top: 1rem;
		}
	}

	&__products {
		$gap: 0.5rem;

		grid-area: products;

		display: grid;

		position: relative;
		z-index: 300;

		grid-template-columns: minmax(#{'calc(25% - ' $gap ')'},1fr);
		grid-template-rows: repeat(var(--row-count), auto) auto;

		grid-auto-columns: minmax(#{'calc(25% - ' $gap ')'},1fr);
	
		grid-column-gap: $gap;
		grid-row-gap: $gap;
	
		grid-auto-flow: column;
	
		padding: 1rem 0;
		padding-bottom: 0;

		margin: 0 $row-padding;
		margin-bottom: 2rem;
		margin-bottom: -1.5rem;
	
		text-align: center;

		overflow-x: scroll;
		scroll-snap-type: x mandatory;

		filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));

		> * {
			display: contents;

			> .snap {
				scroll-snap-align: start;
			}
		}

		&.can-scroll-left {
			@include scrollMask(false, true);
		}

		&.can-scroll-right {
			@include scrollMask(true, false);
		}

		&.can-scroll-left.can-scroll-right {
			@include scrollMask(true, true);
		}

		&::-webkit-scrollbar {
			height: 0;
		}

		scrollbar-width: 0;
	}

	&__controls {
		grid-row-start: 4;
		grid-column-start: 2;

		position: relative;

		height: 100%;

		> .prev,
		> .next {
			display: block;
			height: 4rem;
			width: 4rem;

			background: none;
			border: none;

			outline: none;
			cursor: pointer;

			svg {
				height: 100%;
				width: 100%;
			}

			&.disabled {
				opacity: 0.15;
			}

			&:not(.disabled):hover {
				> svg {
					scale: 1.25;
				}
			}

			&:not(.disabled):focus {
				background: $color-primary;
				border-radius: 100%;
			}
		}

		> .prev {
			position: absolute;
			left: -3rem;
			top: #{'calc(50% - 2rem)'};
		}

		> .next {
			position: absolute;
			right: -3rem;
			top: #{'calc(50% - 2rem)'};
		}
	}

	&__addons-left,
	&__addons-right {
		position: relative;
		max-width: 15rem;
		width: 100%;

		pointer-events: none;
	}

	&__addons-left {
		grid-area: gutter1;

		justify-self: left;

		margin: -2rem 0;
	}

	&__addons-right {
		grid-area: gutter2;

		justify-self: right;

		margin: -2rem 0;
	}

}

@media( max-width: $breakpoint-small ) {
	.c-product-list {
		&__addons-left,
		&__addons-right {
			display: none;
		}

		&__products {
			$gap: 0.5rem;

			grid-template-columns: minmax(#{'calc((100%/3) - ' $gap ')'},1fr);
			grid-auto-columns: minmax(#{'calc((100%/3) - ' $gap ')'},1fr);
		}
	}
}

// Lack of display: contents will break the carefully crafted layout above, so we can
// fall back to something vaugely sensible, if less interesting
@supports not (display: contents) {
	.c-product-list {
		display: block;
		max-width: $body-width;
		margin: 0 auto;
		background: transparent !important;

		&__products {
			display: flex;

			> * {
				display: flex;
				flex-basis: 33%;

				flex-direction: column;

				margin: 0 0.5rem;
			}

			> * > *:nth-child(2) {
				flex-grow: 1;
			}

			.c-product__text {
				background: $color-tan;
			}
		}
	}
}

@media( max-width: $breakpoint-mobile ) {
	.c-product-list {
		display: block;
		position: relative;

		padding-bottom: 2rem;

		&__controls {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;

			z-index: 2000;
			pointer-events: none;

			> * {
				pointer-events: auto;
			}

			> .prev {
				left: 0;
			}

			> .next {
				right: 0;
			}
		}

		&__products {
			margin: 0 2rem;

			grid-template-columns: minmax(85%,1fr);
			grid-auto-columns: minmax(85%,1fr);
		}
	}
}
