
.c-button {

	$skew-angle: -24deg;

	position: relative;
	display: inline-block;

	padding: 0.5rem;

	color: $color-gray;
	background: $color-primary;

	font: 16px/24px museo-sans,Verdana,Geneva,sans-serif;

	font-size: 1rem;
	line-height: 1.5rem;
	text-align: center;
	text-decoration: none;
	font-weight: 700;

	border: none;

	overflow: hidden;
	vertical-align: top;

	cursor: pointer;

	> * {
		position: relative;
		z-index: 100;
		-webkit-font-smoothing: antialiased;
	}

	&--angled {
		padding: 0.5rem 1.5rem;
		transform: skew($skew-angle);

		> * {
			display: inline-block;
			transform: skew(-1 * $skew-angle);
		}
	}

	&--lg {
		padding: 1rem 2rem;
	}

	// Give this an angled overlay on hover
	&::after {
		content: '';

		position: absolute;

		width: 100%;
		height: 100%;

		top: 0;
		left: 0;

		background: lighten($color-primary, 20%);

		transition: transform 0.35s;
		transform: skew(-2 * $skew-angle) translateX(-135%);
	}

	&:hover {
		color: $color-gray;

		&::after {
			transform: skew(-2 * $skew-angle) translateX(135%);
		}
	}

}
