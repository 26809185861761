
.u-anchor-top {
	align-self: start;
}

.u-anchor-bottom {
	align-self: end;
}

.u-anchor-left {
	justify-self: left;
}

.u-anchor-right {
	justify-self: right;
}

.u-anchor-center {
	justify-self: center;
}

.u-anchor-center-vert {
	align-self: center;
}
