
.c-header {
	background: $color-gray-50;
	color: $color-white;

	padding: 0;
	height: 6rem;

	&__container {
		display: flex;
		align-items: center;
	}

	.logo,
	&__phone,
	&__main-site {
		display: inline-block;

		vertical-align: top;

		line-height: 4rem;
		max-height: 4rem;
	}

	.logo {
		max-height: 4rem;
		margin: 1rem 0;

		> svg {
			max-height: 4rem;
			max-width: 11rem;
		}

		&--small {
			display: none;
		}
	}

	&__divider {
		display: inline-block;
		height: 6rem;
		width: 2px;

		margin: 0 2rem;

		background: $color-gray-70;

		transform: skew(-24deg);
	}

	&__phone,
	&__main-site {
		font-size: 1.4rem;
		margin: 1rem 0;

		font-weight: 600;

		> svg {
			max-height: 2rem;
			max-width: 2rem;

			line-height: 2rem;
			margin: 1rem 0;
			margin-right: 0.5rem;
			vertical-align: top;
		}

		.button {
			background: $color-primary;
			padding: 0.5rem 1rem;

			font-size: 1rem;
			text-decoration: none;
		}

		> .main-menu__btn-sm {
			display: none;
		}
	}

	&__phone {
		flex-grow: 1;
	}

	&__i18n-links {
		display: inline-block;
		margin-left: auto;

		> * {
			display: block;

			line-height: 1.5rem;
			text-decoration: none;
			color: $color-white;

			> * {
				vertical-align: top;
			}
		}

		> a {
			color: $color-primary;

			> svg {
				margin-right: 0.25rem;
			}
		}

		.c-header__i18n-link-text-abbr {
			display: none;
		}
	}

	// This needs to be able to float on top of the hero image if necessary
	&__main-site {
		position: relative;
		z-index: 1000;

		line-height: 2.5rem; // Fix button vertical alignment
	}
}


@media( max-width: $breakpoint-mobile ) {

	.c-header {
		height: 4rem;

		.logo {
			max-height: 3rem;
			margin: 0.5rem 0;
			padding-right: 0.5rem;

			> svg {
				max-height: 3rem;
				max-width: 8rem;
			}
		}

		&__divider {
			height: 4rem;
			margin: 0 1rem;
		}

		&__phone {
			font-size: 1rem;
			margin: 0.5rem 0;
			line-height: 3rem;

			> svg {
				display: none;

				///width: 1.5rem;
				//height: 1.5rem;

				//margin: 0.75rem 0;
				//margin-right: 0.5rem;
			}
		}

		&__main-site {
			margin: 0;
		}

		&__i18n-links {
			> * {
				display: inline-block;
			}

			.c-header__i18n-caption,
			.c-header__i18n-link-text-full {
				display: none;
			}

			.c-header__i18n-link-text-abbr {
				display: inline-block;
			}
		}
	}

}

@media( max-width: 768px ) {
	.c-header {
		&__phone,
		&__main-site {
			//height: 2rem;
			line-height: 2rem;
			margin: 0.5rem 0;
		}

		&__main-site {
			font-size: 0;

			> .button {
				padding: 0.5rem;
				line-height: 1rem;
			}

			.main-menu__btn-lg {
				display: none;
			}
			
			.main-menu__btn-sm {
				display: inline-block;
			}
		}

		&__i18n-links {
			.c-header__i18n-link-text-abbr {
				display: none;
			}
		}
	}
}

@media( max-width: $breakpoint-tiny ) {

	.c-header {

		height: 3.5rem;

		.logo {
			max-height: 2rem;

			> svg {
				vertical-align: top;
				max-height: 2rem;
				max-width: 5rem;
			}
		}

		&__divider {
			display: none;
		}


		> * > * + * {
			margin-left: 0.75rem;
		}
	}

}

@media( max-width: 500px ) {
	.c-header {

		height: 3rem;

		.logo--full {
			display: none;
		}

		.logo--small {
			display: block;

			> svg {
				display: block;
				height: 2rem;
				width: 2rem;
			}
		}

		&__container {
			padding: 0 1rem !important;
		}

		/*.logo {
			display: none;
		}*/

		&__phone {
			margin-left: 0 !important;
		}
	}
}
