
@use '../settings/colors';
@use '../settings/inputs';

.c-form-field {
	position: relative;
	display: block;

	// The underline consists of two stacked pseudo-elements,
	// one gray, one yellow. The yellow one is displayed only
	// when the form field is focused.
	&::before,
	&::after {
		content: '';

		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 3px;
	
		z-index: 10;
	
		background: colors.$gray;
	
		pointer-events: none;
	}

	// Focus state underline
	&::after {
		background: #e9bc16;
	
		height: 4px;
	
		// When the element is focused, scaleX will get set to 1.
		// The 'transition' results in this being animated.
		transform: scaleX(0);
		transition: transform 0.325s;
		transition-timing-function: ease-out;
	}

	&__input {
		// Fill the full width of the container.
		width: 100%;

		// Overwrite some default input element properties
		font-size: inherit;
		line-height: 1;
		padding: 0.75rem;
	
		color: inherit;
		font-family: inherit;
	
		border: none;

		// Slight translucency means this will look correct on both light
		// and dark backgrounds
		background: inputs.$background;

		// A focus indicator is provided via the underline pseudo-element,
		// so we don't need the browser's default one.
		&:focus {
			outline: none;
		}
	}

	// This sits in the middle of the form field by default, and slides up
	// above the text input when it's focused.
	&__label {
		display: block;

		position: absolute;
		left: 0;
		top: 0;
		height: 2.5rem;
	
		padding: 0.5rem 0.75rem;
		line-height: 1.5rem;
	
		color: inherit;
		opacity: 1;
		font-weight: 600;
	
		transition: transform 0.2s, opacity 0.2s;
		transform-origin: left;
	
		// Don't prevent the user from being able to click on the underlying form field
		pointer-events: none;
	}

	// A contextually-visible validation error indicator.
	// Hidden unless the form fails to validate.
	&__error {
		display: none;
		position: relative;
	
		// Take up only the minimum width needed for the text
		width: max-content;
	
		background: colors.$gray-70;
		color: colors.$white;
		padding: 0.25rem;
		padding-right: 0.5rem;
		font-size: 0.85rem;
		line-height: 1rem;
		vertical-align: top;
		margin: 0.5rem;
		margin-top: 0.25rem;
	
		border-radius: 2px;

		// An SVG X-mark
		&::before {
			content: '';
			display: inline-block;

			width: 1.25rem;
			height: 1.25rem;
			line-height: 1rem;
			vertical-align: top;
			margin: -0.125rem;
			margin-right: 0.25rem;

			// Using inline SVG here to prevent any external dependencies.
			// Optimally, this would be added in as a separate icon component.
			background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.4111 12.7071C15.0206 12.3166 15.0206 11.6834 15.4111 11.2929L20.2929 6.41109C20.6834 6.02056 20.6834 5.3874 20.2929 4.99687L19.0031 3.70711C18.6126 3.31658 17.9794 3.31658 17.5889 3.70711L12.7071 8.58891C12.3166 8.97944 11.6834 8.97944 11.2929 8.58891L6.41109 3.70711C6.02056 3.31658 5.3874 3.31658 4.99687 3.70711L3.70711 4.99687C3.31658 5.3874 3.31658 6.02056 3.70711 6.41109L8.58891 11.2929C8.97944 11.6834 8.97944 12.3166 8.58891 12.7071L3.70711 17.5889C3.31658 17.9794 3.31658 18.6126 3.70711 19.0031L4.99687 20.2929C5.3874 20.6834 6.02056 20.6834 6.41109 20.2929L11.2929 15.4111C11.6834 15.0206 12.3166 15.0206 12.7071 15.4111L17.5889 20.2929C17.9794 20.6834 18.6126 20.6834 19.0031 20.2929L20.2929 19.0031C20.6834 18.6126 20.6834 17.9794 20.2929 17.5889L15.4111 12.7071Z" fill="%23FF857B"/></svg>');
			background-position: center;
			background-size: contain;
		}

		// A pure-CSS arrow
		&::after {
			content: '';
	
			position: absolute;
			top: -1rem;
			left: 0.5rem;
			height: 0;
			width: 0;
		
			z-index: 1000;
		
			border: 0.5rem solid transparent;
			border-bottom: 0.5rem solid colors.$gray-70;
		
			pointer-events: none;
		
			box-sizing: content-box;
		}
	}


	// Contextual states
	// --------------------------------------------------------------
	// In this implementation, data attributes control the display
	// of certain elements in the input group. Classes could also be
	// used.


	// Does the form field have focus?
	&[data-is-focused] {
		// Show the yellow focus indicator bar
		&::after {
			transform: scaleX(1);
		}

		// Add a subtle drop shadow
		.c-form-field__input {
			box-shadow: inputs.$drop-shadow;
		}
	}

	// Should the label float above the input field?
	&[data-hide-placeholder] {
		.c-form-field__label {
			transform: scale(0.8) translateY(-1.5rem);
			opacity: 1;
		}
	}

	// Should the error indicator be displayed?
	&[data-has-error] {
		// Shows the inline error display
		.c-form-field__error {
			display: block;
		}

		// Colorizes the form field's label
		.c-form-field__label {
			color: colors.$danger-90;
		}

		// Adds a subtle coloration to the text field itself
		.c-form-field__input {
			background: transparentize(colors.$danger-50, 0.9);
		}
	}

}
