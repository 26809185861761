
.c-split-block {
	display: grid;
	grid-template-columns: 1fr ($body-width/2 - 1rem) ($body-width/2 - 1rem)  1fr;
	grid-template-rows: auto auto 1fr;

	grid-column-gap: 2rem;

	grid-template-areas:
		"gutter1 title image image"
		"divider divider image image"
		"gutter3 content image image";

	&--third {
		grid-template-columns: 1fr ($body-width * (2/3) - 1rem) ($body-width * (1/3) - 1rem) 1fr;
	}

	&--dual {
		grid-template-columns: 1fr ($body-width * (2/3)) - 2rem 1fr;

		grid-template-areas:
		"image title image2"
		"image divider image2"
		"image content image2";
	}

	&--flip {
		grid-template-areas:
			"image image title gutter1"
			"image image divider divider"
			"image image content gutter3";
	}

	&--third.c-split-block--flip {
		grid-template-columns: 1fr ($body-width * (1/3) - 1rem) ($body-width * (2/3) - 1rem) 1fr;
	}

	&--intro {
		.c-split-block__image {
			min-height: 28rem;
			max-height: 40rem;
		}
	}

	&--large {
		.c-split-block__text {
			font-size: 1.125rem;
			line-height: 2rem;
		}
	}

	&--img-collapse {
		.c-split-block__image {
			min-height: 18rem;
		}
	}

	&--img-center {
		.c-split-block__image img {
			object-position: center;
		}
	}

	&__title {
		grid-area: title;
		margin: 0;

		padding: 1rem $row-padding;
	}

	&__divider {
		@include divider-line;

		grid-area: divider;

		margin: 0.5rem 0;
		margin-inline: unset; // Overwrite default <hr> behavior
	}

	&__text {
		grid-area: content;
		align-self: center;

		padding: 1rem $row-padding;

		p {
			margin: 0;
		}

		> p + p {
			margin-top: 1rem;
		}
	}

	&__image,
	&__image2 {
		--bg-image: #{$color-gray};

		position: relative;
		z-index: 100;

		grid-area: image;
		justify-self: left;
		align-self: center;

		background: var(--bg-image);
		background-size: cover;
		background-position: center;
	
		width: 100%;
		height: 100%;
		min-height: 24rem;
		max-height: 30rem;
		max-width: 50rem;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: top;

			position: absolute;
			display: block;
		}
	}

	&__image2 {
		grid-area: image2;
		justify-self: right;
	}

}

@media( max-width: $breakpoint-small ) {
	// Force this to 50/50 split when the screen gets too small
	.c-split-block {
		grid-template-columns: 1fr ($body-width/2 - 1rem) ($body-width/2 - 1rem)  1fr;
	}
}

@media( max-width: $breakpoint-mobile ) {
	.c-split-block {
		display: block;

		&__image,
		&__image2 {
			display: none;
		}
	}
}
