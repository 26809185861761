
.u-affix {
	// This isn't actually implemented, it would be the better way to do it, but there wasn't time to update the JS code
	--offset: 0px;

	transform: translateY(var(--offset));

	@media( max-width: 70rem ) {
		transform: unset;
	}
}
