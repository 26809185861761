
@use '../settings/responsive';

.l-flex {
	display: flex;

	&--center {
		justify-content: center;
	}

	&--center-vert {
		align-items: center;
	}

	&--space-between {
		justify-content: space-between;
	}

	&--column {
		flex-direction: column;
	}

	@include responsive.addBreakpoint('small') {
		display: block;
	}

	@include responsive.addBreakpoint('mobile') {
		display: block;
	}

	@include responsive.addBreakpoint('tiny') {
		display: block;
	}

	&__item {
		&--grow {
			flex-grow: 1;
		}
	}
}
