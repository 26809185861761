
@mixin bf-heading($size, $color: white, $weight: 500) {
	font-size: $size;
	line-height: $size;
	font-weight: $weight;

	margin: 0;
	padding: 0;

	color: $color;
}

@keyframes bf-heading-fade-in {
	0% {
		opacity: 0;
		transform: translateY(100%);
	}

	100% {
		opacity: 1;
		transform: translateY(0%);
	}
}

.c-header-black-friday {
	background: black url('../img/banner_black_friday.jpg');
	background-size: contain;
	background-repeat: no-repeat;
	background-position-x: right;
	color: $color-white;

	padding: 3rem 0;

	&__heading1 {
		@include bf-heading(3rem, $color-white, 700);
	}

	&__heading2 {
		@include bf-heading(3rem, $color-white, 300);
	}

	&__subtitle {
		@include bf-heading(1.5rem, $color-primary-darker, 500);
	}

	&__big-deals {
		@include bf-heading(8rem, $color-primary-darker, 700);
	}

	&__conditions {
		font-style: italic;
	}

	&__title > * + * {
		margin-top: 0.5rem;
	}

	&__title > * {
		animation-name: bf-heading-fade-in;
		animation-duration: 0.5s;
		animation-fill-mode: both;
	}

	@for $i from 2 to 7 {
		&__title > :nth-child(#{$i}) {
			animation-delay: 0.15s * $i;
		}
	}

	@media( max-width: 600px ) {
		&__heading1,
		&__heading2 {
			font-size: 2rem;
			line-height: 2rem;
		}

		&__big-deals {
			font-size: 5rem;
			line-height: 5rem;
		}
	}

}
