
@keyframes bounce-in {
	0% {
		transform: translateY(var(--offset)) scale(1) rotate(0deg);
	}

	33% {
		transform: translateY(var(--offset)) scale(1.025) rotate(1deg);
	}


	66% {
		transform: translateY(var(--offset)) scale(1.025) rotate(-1deg);
	}

	100% {
		transform: translateY(var(--offset)) scale(1) rotate(0deg);
	}
}

.c-form-black-friday {
	position: relative;
	top: -10rem;

	padding: 1rem;

	background: $color-tan;

	box-shadow: 0 4px 12px rgba(0,0,0,0.15);

	transform: translateY(var(--offset)) scale(1) rotate(0deg);

	&--wiggle {
		animation-name: bounce-in;
		animation-duration: 0.5s;
		animation-fill-mode: both;
		animation-timing-function: ease-in-out;
	}

	&:focus-within {
		box-shadow: 0 8px 24px rgba(0,0,0,0.25);
		outline: 2px solid $color-primary-darker;
	}

	//border: 4px solid $color-gray;

	@media( max-width: 70rem ) {
		top: 0;
		margin-top: 2rem;

		box-shadow: none;
	}

	h2 {
		font-weight: 900;
		text-align: center;
		margin-bottom: 0;
	}

	.form-block {
		padding: 0;
		background: none;
	}
}
