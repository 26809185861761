
// Responsive breakpoints
// --------------------------

$breakpoints: (
	'small': 1100px,
	'mobile': 980px,
	'tiny': 600px,
);

@mixin breakpoint($size) {
	@media screen and (max-width: map-get($breakpoints, $size)) {
		@content;
	}
}

@mixin addBreakpoint($size, $type: 'break', $dir: 'max') {
	&--#{$type}-#{$size} {
		@media screen and (#{$dir}-width: map-get($breakpoints, $size)) {
			@content;
		}
	}
}
