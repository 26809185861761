
@use '../settings/colors';
@use '../settings/inputs';

.c-checkbox,
.c-radio-button {
	position: relative;
	padding: 0.5rem 0;

	&__input,
	&__label {
		line-height: 1.5rem;
		display: inline-block;
		vertical-align: top;
	}

	// Hide the default input control
	&__input {
		opacity: 0;
		width: 1.5rem;
		height: 1.5rem;
	}

	&__label {
		font-weight: 600;
		padding-left: 0.25rem;

		// Replace the input with pseudo elements on the label element
		// ::before is the default state, ::after is the checked state
		&::before,
		&::after {
			content: '';

			position: absolute;
			display: inline-block;

			width: 1rem;
			height: 1rem;
			line-height: 1.5rem;

			top: 0.5rem;
			left: 0;

			vertical-align: top;

			margin-left: 0.25rem;
			margin-top: 0.25rem;
		}

		&::before {
			border: 3px solid colors.$gray-50;
		}

		&::after {
			// By default, this is hidden by scaling it down to zero, it's
			// animated later
			transform: scale(0);
			transition: transform 0.25s ease-out;

			margin: 0;
			width: 1.5rem;
			height: 1.5rem;

		}
	}

	// This powers the core of the UI component - the pseudo-elements will be
	// changed when the checkbox is checked.
	&__input:checked + &__label {
		color: colors.$black;

		&::before {
			border-color: colors.$black;
		}

		&::after {
			transform: scale(1);
		}
	}

	// Focus state
	&::before {
		content: '';

		position: absolute;
		bottom: 0;
		top: 0;
		left: -3px;

		border-left: 3px solid colors.$primary-darker;

		transform: scaleY(0);
		transition: transform 0.125s ease-out;
	}

	// Not supported in IE11, but should be fine everywhere else
	&:focus-within {
		background: inputs.$background;

		&::before {
			transform: scaleY(1);
		}
	}
}

// Radio-button specific styles
.c-radio-button {

	&__label {
		&::before {
			border-radius: 100%;

			// The circle is slightly larger than 1rem, in order to have the same optical size
			// as the 1rem squares for checkboxes
			width: 1.125rem;
			height: 1.125rem;

			margin-left: (1.5rem - 1.125rem) / 2;
			margin-top: (1.5rem - 1.125rem) / 2;

		}

		&::after {
			background: colors.$primary-darker;
			border-radius: 100%;

			width: 0.5rem;
			height: 0.5rem;
			
			margin-top: 0.5rem;
			margin-left: 0.5rem;
		}
	}

}

// Checkbox-specific styles
.c-checkbox {

	&__label {
		&::after {
			// Add an SVG checkmark
			background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 12L12 16L20 4" stroke="%23E9BC16" stroke-width="4" stroke-linecap="round"/></svg>');
		}
	}

}
