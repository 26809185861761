.c-product-search-block {
	display: grid;
	grid-template-columns: 2fr auto 5fr auto 2fr;
	grid-template-rows: 6rem;

	background: white;

	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);

	> * {
		padding: 0.5rem 1rem;
	}

	> .c-angle-trim {
		padding: 0;
	}

	&__title,
	&__cta {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	&__title {
		align-items: flex-start;

		background: $color-gray-50;
		color: $color-primary;

		border-left: 6px solid $color-primary;

		> * {
			color: $color-primary;
		}

		h4 {
			font-size: 1.25rem;
			line-height: 1.5;
			font-weight: bold;
		}

		span {
			font-size: 1.125rem;
			font-weight: bold;
		}
	}

	&__price span {
		color: white;
		font-size: 1rem;
		font-weight: 600;
		vertical-align: middle;
	}

	&__specs {
		margin: 0;
		list-style: none;
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		justify-content: center;
	}

	&__spec-entry {
		line-height: 1.5rem;

		> *:nth-child(1) {
			min-width: 4rem;
			display: inline-block;
		}

		> *:nth-child(2) {
			font-weight: bold;
			color: black;
		}
	}

	&__cta {
		align-items: center;
	}

	@media( max-width: 1200px ) {
		display: block;

		> .c-angle-trim {
			display: none;
		}
	}
}
