
.c-hero-list {
	font-size: 1.125rem;

	list-style: none;
	padding-left: 0;

	> li {
		padding: 0.25rem 0;

		&::before {
			content: '•';

			display: inline-block;
			padding: 0 0.5rem;

			font-weight: 900;

			color: $color-primary-darker;
		}

		> em {
			font-weight: 700;
			font-style: normal;
		}
	}
}
