
// IE doesn't support CSS grid, so we're adding in some extremely basic rules to make
// this not blatantly broken, then immediately un-doing them

main > * {
	max-width: $body-width;
	margin: 0 auto;
}

.icon {
	display: none;
}

.c-split-block__image {
	display: none;
}

html body .c-introduction .c-split-block__title {
	margin-right: 0;
}

.c-product {
	margin-bottom: 1rem;
}

@supports (display: grid) {
	main > * {
		max-width: unset;
		margin: unset;
	}

	.icon {
		display: initial;
	}

	.c-split-block__image {
		display: initial;
	}

	.c-product {
		margin-bottom: unset;
	}

	html body .c-introduction .c-split-block__title {
		margin-right: -12rem;
	}
}
