.c-product-search {
	margin: 1.5rem 0;

	> * + * {
		margin-top: 1rem;
	}

	&__form {
		display: flex;

		> * {
			flex-grow: 1;
		}
	}

	&__results {
		> * + * {
			margin-top: 1rem;
		}
	}

	&__show-more {
		display: flex;
		justify-content: center;
		padding: 1rem;
	}

	@media( max-width: 1200px ) {
		&__form {
			flex-wrap: wrap;
		}

		&__results {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-column-gap: 1rem;
			grid-row-gap: 1rem;

			> * + * {
				margin-top: 0;
			}
		}
	}

	@media( max-width: 768px ) {
		&__results {
			display: block;

			> * + * {
				margin-top: 1rem;
			}
		}
	}
}