
.l-grid {
	display: grid;
	grid-template-columns: 2fr 1fr;

	&--collapse-sm {
		@media( max-width: 70rem ) {
			display: block;
		}
	}
}
