.l-row {

	&--tan {
		background: $color-tan;
	}

	&__inner,
	> * {
		max-width: $body-width;
		margin: 0 auto;
		padding: 0 $row-padding;
	}

}
