
.c-addons {
	position: absolute;
	z-index: 200;

	top: 0;
	left: 0;
	right: 0;
	bottom: 0;

	> * {
		position: absolute;
	}

	> .addon-top {
		top: 0;
	}

	> .addon-bottom {
		bottom: 0;
	}

	> .addon-right {
		right: 0;
	}

	> .addon-left {
		left: 0;
	}

	> .addon-scale-vertical {
		height: 100%;
		width: auto;
	}

	> .addon-scale-horizontal {
		width: 100%;
	}

	> .nudge-left-sm {
		transform: translateX(-2rem);
	}

	> .nudge-left-md {
		transform: translateX(-35%);
	}

	> .nudge-left-lg {
		transform: translateX(-50%);
	}

	> .nudge-right-sm {
		transform: translateX(2rem);
	}

	> .nudge-right-md {
		transform: translateX(35%);
	}

	> .nudge-right-lg {
		transform: translateX(50%);
	}
}
