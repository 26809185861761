.c-feature-list {
	display: grid;

	grid-template-columns: 1fr minmax(auto, $body-width) 1fr;
	grid-template-rows: auto auto auto;
	grid-template-areas:
		"gutter1 title   gutter2"
		"gutter1 divider divider"
		"gutter1 content gutter3";

	&__title {
		grid-area: title;
		margin: 0;
		padding: 1rem $row-padding;
	}

	&__divider {
		@include divider-line;

		grid-area: divider;
	
		margin: 0.5rem 0;
		margin-inline: unset; /* Overwrite default <hr> behavior */
	}

	&__stripe {
		grid-column-start: 1;
		grid-column-end: span 3;
		grid-row-start: gutter3;
	
		align-self: center;
	
		background: $color-tan;
	
		height: 4rem;
		width: 100%;
	}

	&__container {
		grid-area: content;
	
		display: grid;
		z-index: 100;

		padding: 1rem 0;
	
		grid-template-columns: 1fr;
		grid-template-rows: 1fr;
	
		grid-auto-columns: 1fr;
		grid-auto-flow: column;
	
		grid-column-gap: 2rem;
	}
}

@media( max-width: $breakpoint-tiny ) {
	.c-feature-list {
		display: block;

		&__stripe {
			display: none;
		}

		&__container {
			display: block;

			> * + * {
				margin-top: 2rem;
			}
		}
	}
}