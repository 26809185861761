.u-clip {
	position: relative;

	&--start,
	&--end {
		position: relative;
	}

	&--start::before,
	&--start-striped::before,
	&--start-border::before {
		content: '';

		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
	
		height: 100%;
		width: 100%;
	
		background: inline('svg/angled-clip-start.svg');
		background-repeat: no-repeat;
		background-position-x: 0%;
		background-size: 100% 100%; // A rounding bug prevents this from completely covering the height unless this is set

		z-index: 10;
	}

	&--start-striped::before {
		background-image: inline('svg/angled-clip-start-stripe.svg');
	}

	&--start-border::before {
		background-image: inline('svg/angled-clip-start-border.svg');
		background-size: cover;
	}

	&--end::after,
	&--end-border::before {
		content: '';

		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
	
		height: 100%;
		width: 100%;
	
		background: inline('svg/angled-clip-end.svg');
		background-repeat: no-repeat;
		background-position-x: 100%;
		background-size: cover; // A rounding bug prevents this from completely covering the height unless this is set

		z-index: 10;
	}

	&--end-border::before {
		background-image: inline('svg/angled-clip-end-border.svg');
	}
}
