
$icon-size: 4.5rem;

.selling-point {
	display: grid;

	grid-template-columns: $icon-size 1fr;
	grid-column-gap: 2rem;

	margin-top: 2rem;

	> .image {
		width: 100%;
		max-width: $icon-size;
	}

	> .content > * {
		transform-origin: right;
	}
}
