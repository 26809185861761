
.c-product {

	> * {
		padding: 1rem;

		background: $color-white;

		//box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

		> * {
			margin: 0;
		}

		> * + * {
			margin-top: 1rem;
		}
	}

	&__title,
	&__accent {
		background: $color-gray-50;
		color: $color-white;

		font-weight: 600;
	}

	&__title {
		font-size: 1.25rem;
		font-weight: 700;

		color: $color-primary;
	}

	&--expanded {
		.c-product__text {
			grid-row-end: span 2;

			background: $color-gray-50;
			color: $color-white;
		}
	}
}
