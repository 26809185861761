
@use '../settings/colors';

.c-table {
	min-width: 100%;
	overflow-x: auto;

	border-collapse: collapse;
	border-spacing: 0;

	td, th {
		padding: 0.5rem 1rem;
	}

	th {
		background: colors.$tan;
		text-align: left;
		color: colors.$black;
	}

	td {
		border-bottom: 2px solid colors.$gray-0;
	}

	&--accent {
		border-collapse: separate;
		border-spacing: 2px;

		th, td {
			padding: 0.5rem;
			background: colors.$tan;
	
			border: 2px solid colors.$white;
		}
	
		th {
			background: colors.$primary;
			color: colors.$black;
			font-weight: 700;

			text-align: center;
		}

		td:first-child {
			background: colors.$gray-70;
			color: colors.$white;
			font-weight: 700;
			text-align: center;
		}
	}

	&__sort-button {
		background: none;
		border: none;
		font-size: inherit;
		line-height: inherit;

		width: 1.5rem;
		height: 1.5rem;
		padding: 0;
		margin-left: 0.5rem;
		
		vertical-align: top;

		float: right;

		&--descending,
		&--ascending {
			background-position: center;
			background-size: contain;
		}

		&--descending {
			//background-image: url('symbol.svg#chevron-down');
		}

		&--ascending {
			//background-image: url('symbol.svg#chevron-up');
		}
	}

	&__cell {
		&--keep-together {
			white-space: nowrap;
		}
	}
}
