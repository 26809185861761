@charset "UTF-8";
.c-checkbox,
.c-radio-button {
  position: relative;
  padding: 0.5rem 0;
}
.c-checkbox__input, .c-checkbox__label,
.c-radio-button__input,
.c-radio-button__label {
  line-height: 1.5rem;
  display: inline-block;
  vertical-align: top;
}
.c-checkbox__input,
.c-radio-button__input {
  opacity: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.c-checkbox__label,
.c-radio-button__label {
  font-weight: 600;
  padding-left: 0.25rem;
}
.c-checkbox__label::before, .c-checkbox__label::after,
.c-radio-button__label::before,
.c-radio-button__label::after {
  content: "";
  position: absolute;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  line-height: 1.5rem;
  top: 0.5rem;
  left: 0;
  vertical-align: top;
  margin-left: 0.25rem;
  margin-top: 0.25rem;
}
.c-checkbox__label::before,
.c-radio-button__label::before {
  border: 3px solid #636355;
}
.c-checkbox__label::after,
.c-radio-button__label::after {
  transform: scale(0);
  transition: transform 0.25s ease-out;
  margin: 0;
  width: 1.5rem;
  height: 1.5rem;
}
.c-checkbox__input:checked + .c-checkbox__label,
.c-checkbox__input:checked + .c-radio-button__label,
.c-radio-button__input:checked + .c-checkbox__label,
.c-radio-button__input:checked + .c-radio-button__label {
  color: black;
}
.c-checkbox__input:checked + .c-checkbox__label::before,
.c-checkbox__input:checked + .c-radio-button__label::before,
.c-radio-button__input:checked + .c-checkbox__label::before,
.c-radio-button__input:checked + .c-radio-button__label::before {
  border-color: black;
}
.c-checkbox__input:checked + .c-checkbox__label::after,
.c-checkbox__input:checked + .c-radio-button__label::after,
.c-radio-button__input:checked + .c-checkbox__label::after,
.c-radio-button__input:checked + .c-radio-button__label::after {
  transform: scale(1);
}
.c-checkbox::before,
.c-radio-button::before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: -3px;
  border-left: 3px solid #e9bc16;
  transform: scaleY(0);
  transition: transform 0.125s ease-out;
}
.c-checkbox:focus-within,
.c-radio-button:focus-within {
  background: rgba(0, 0, 0, 0.04);
}
.c-checkbox:focus-within::before,
.c-radio-button:focus-within::before {
  transform: scaleY(1);
}

.c-radio-button__label::before {
  border-radius: 100%;
  width: 1.125rem;
  height: 1.125rem;
  margin-left: 0.1875rem;
  margin-top: 0.1875rem;
}
.c-radio-button__label::after {
  background: #e9bc16;
  border-radius: 100%;
  width: 0.5rem;
  height: 0.5rem;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
}

.c-checkbox__label::after {
  background-image: url('data:image/svg+xml;utf8,<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 12L12 16L20 4" stroke="%23E9BC16" stroke-width="4" stroke-linecap="round"/></svg>');
}

.c-form-field {
  position: relative;
  display: block;
}
.c-form-field::before, .c-form-field::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  z-index: 10;
  background: #636355;
  pointer-events: none;
}
.c-form-field::after {
  background: #e9bc16;
  height: 4px;
  transform: scaleX(0);
  transition: transform 0.325s;
  transition-timing-function: ease-out;
}
.c-form-field__input {
  width: 100%;
  font-size: inherit;
  line-height: 1;
  padding: 0.75rem;
  color: inherit;
  font-family: inherit;
  border: none;
  background: rgba(0, 0, 0, 0.04);
}
.c-form-field__input:focus {
  outline: none;
}
.c-form-field__label {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 2.5rem;
  padding: 0.5rem 0.75rem;
  line-height: 1.5rem;
  color: inherit;
  opacity: 1;
  font-weight: 600;
  transition: transform 0.2s, opacity 0.2s;
  transform-origin: left;
  pointer-events: none;
}
.c-form-field__error {
  display: none;
  position: relative;
  width: max-content;
  background: #3F3F37;
  color: white;
  padding: 0.25rem;
  padding-right: 0.5rem;
  font-size: 0.85rem;
  line-height: 1rem;
  vertical-align: top;
  margin: 0.5rem;
  margin-top: 0.25rem;
  border-radius: 2px;
}
.c-form-field__error::before {
  content: "";
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  line-height: 1rem;
  vertical-align: top;
  margin: -0.125rem;
  margin-right: 0.25rem;
  background-image: url('data:image/svg+xml;utf8,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M15.4111 12.7071C15.0206 12.3166 15.0206 11.6834 15.4111 11.2929L20.2929 6.41109C20.6834 6.02056 20.6834 5.3874 20.2929 4.99687L19.0031 3.70711C18.6126 3.31658 17.9794 3.31658 17.5889 3.70711L12.7071 8.58891C12.3166 8.97944 11.6834 8.97944 11.2929 8.58891L6.41109 3.70711C6.02056 3.31658 5.3874 3.31658 4.99687 3.70711L3.70711 4.99687C3.31658 5.3874 3.31658 6.02056 3.70711 6.41109L8.58891 11.2929C8.97944 11.6834 8.97944 12.3166 8.58891 12.7071L3.70711 17.5889C3.31658 17.9794 3.31658 18.6126 3.70711 19.0031L4.99687 20.2929C5.3874 20.6834 6.02056 20.6834 6.41109 20.2929L11.2929 15.4111C11.6834 15.0206 12.3166 15.0206 12.7071 15.4111L17.5889 20.2929C17.9794 20.6834 18.6126 20.6834 19.0031 20.2929L20.2929 19.0031C20.6834 18.6126 20.6834 17.9794 20.2929 17.5889L15.4111 12.7071Z" fill="%23FF857B"/></svg>');
  background-position: center;
  background-size: contain;
}
.c-form-field__error::after {
  content: "";
  position: absolute;
  top: -1rem;
  left: 0.5rem;
  height: 0;
  width: 0;
  z-index: 1000;
  border: 0.5rem solid transparent;
  border-bottom: 0.5rem solid #3F3F37;
  pointer-events: none;
  box-sizing: content-box;
}
.c-form-field[data-is-focused]::after {
  transform: scaleX(1);
}
.c-form-field[data-is-focused] .c-form-field__input {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 3px 6px;
}
.c-form-field[data-hide-placeholder] .c-form-field__label {
  transform: scale(0.8) translateY(-1.5rem);
  opacity: 1;
}
.c-form-field[data-has-error] .c-form-field__error {
  display: block;
}
.c-form-field[data-has-error] .c-form-field__label {
  color: #6E3D39;
}
.c-form-field[data-has-error] .c-form-field__input {
  background: rgba(255, 133, 123, 0.1);
}

.c-table {
  min-width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
  border-spacing: 0;
}
.c-table td, .c-table th {
  padding: 0.5rem 1rem;
}
.c-table th {
  background: #f5f1e9;
  text-align: left;
  color: black;
}
.c-table td {
  border-bottom: 2px solid #EEEEED;
}
.c-table--accent {
  border-collapse: separate;
  border-spacing: 2px;
}
.c-table--accent th, .c-table--accent td {
  padding: 0.5rem;
  background: #f5f1e9;
  border: 2px solid white;
}
.c-table--accent th {
  background: #F4DC00;
  color: black;
  font-weight: 700;
  text-align: center;
}
.c-table--accent td:first-child {
  background: #3F3F37;
  color: white;
  font-weight: 700;
  text-align: center;
}
.c-table__sort-button {
  background: none;
  border: none;
  font-size: inherit;
  line-height: inherit;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0;
  margin-left: 0.5rem;
  vertical-align: top;
  float: right;
}
.c-table__sort-button--descending, .c-table__sort-button--ascending {
  background-position: center;
  background-size: contain;
}
.c-table__cell--keep-together {
  white-space: nowrap;
}

.l-flex {
  display: flex;
}
.l-flex--center {
  justify-content: center;
}
.l-flex--center-vert {
  align-items: center;
}
.l-flex--space-between {
  justify-content: space-between;
}
.l-flex--column {
  flex-direction: column;
}
@media screen and (max-width: 1100px) {
  .l-flex--break-small {
    display: block;
  }
}
@media screen and (max-width: 980px) {
  .l-flex--break-mobile {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  .l-flex--break-tiny {
    display: block;
  }
}
.l-flex__item--grow {
  flex-grow: 1;
}

main > * {
  max-width: 70rem;
  margin: 0 auto;
}

.icon {
  display: none;
}

.c-split-block__image {
  display: none;
}

html body .c-introduction .c-split-block__title {
  margin-right: 0;
}

.c-product {
  margin-bottom: 1rem;
}

@supports (display: grid) {
  main > * {
    max-width: unset;
    margin: unset;
  }

  .icon {
    display: initial;
  }

  .c-split-block__image {
    display: initial;
  }

  .c-product {
    margin-bottom: unset;
  }

  html body .c-introduction .c-split-block__title {
    margin-right: -12rem;
  }
}
@font-face {
  font-family: "museo-sans";
  src: url("/fonts/MuseoSans_300-webfont.woff2") format("woff2"), url("/fonts/MuseoSans_300-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "museo-sans";
  src: url("/fonts/MuseoSans_500-webfont.woff2") format("woff2"), url("/fonts/MuseoSans_500-webfont.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "museo-sans";
  src: url("/fonts/MuseoSans_700-webfont.woff2") format("woff2"), url("/fonts/MuseoSans_700-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "museo-sans";
  src: url("/fonts/MuseoSans_900-webfont.woff2") format("woff2"), url("/fonts/MuseoSans_900-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
h1, h2, h3, h4, h5, h6 {
  color: #636355;
  margin: 0;
  margin-bottom: 1rem;
}
h1 > em, h2 > em, h3 > em, h4 > em, h5 > em, h6 > em {
  font-style: inherit;
  font-weight: 700;
  box-shadow: inset 0 -0.1em 0 white, inset 0 -0.35em 0 #F4DC00;
}

h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 2rem;
}
h2 > em, h3 > em, h4 > em, h5 > em, h6 > em {
  box-shadow: inset 0 -0.1em 0 white, inset 0 -0.5em 0 #F4DC00;
}

h1 {
  font-weight: 400;
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.8rem;
}

h3 {
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
}

h4 {
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

h5 {
  font-size: 1.1rem;
  margin-bottom: 0.5rem;
}

h6 {
  font-size: 1rem;
  margin-bottom: 0.25rem;
}

html, body {
  font: 16px/24px "museo-sans", Verdana, Geneva, sans-serif;
  color: #636355;
}

a,
a:visited,
a:active {
  color: black;
  text-decoration: underline;
}
a:hover,
a:visited:hover,
a:active:hover {
  color: #e9bc16;
}

/*.block {
	background: white;
}*/
.embedsocial-reviews {
  display: none !important;
}

.c-table {
  min-width: 100%;
  border-collapse: separate;
}
.c-table__cell {
  background: #f5f1e9;
  color: #636355;
  padding: 0.5rem;
  border: 2px solid white;
}
.c-table__cell--callout, .c-table__cell--header {
  background: #636355;
  color: white;
  font-weight: 700;
}
.c-table__cell--callout {
  text-align: center;
  color: #F4DC00;
}

.c-split-block {
  display: grid;
  grid-template-columns: 1fr 34rem 34rem 1fr;
  grid-template-rows: auto auto 1fr;
  grid-column-gap: 2rem;
  grid-template-areas: "gutter1 title image image" "divider divider image image" "gutter3 content image image";
}
.c-split-block--third {
  grid-template-columns: 1fr 45.6666666667rem 22.3333333333rem 1fr;
}
.c-split-block--dual {
  grid-template-columns: 1fr 44.6666666667rem 1fr;
  grid-template-areas: "image title image2" "image divider image2" "image content image2";
}
.c-split-block--flip {
  grid-template-areas: "image image title gutter1" "image image divider divider" "image image content gutter3";
}
.c-split-block--third.c-split-block--flip {
  grid-template-columns: 1fr 22.3333333333rem 45.6666666667rem 1fr;
}
.c-split-block--intro .c-split-block__image {
  min-height: 28rem;
  max-height: 40rem;
}
.c-split-block--large .c-split-block__text {
  font-size: 1.125rem;
  line-height: 2rem;
}
.c-split-block--img-collapse .c-split-block__image {
  min-height: 18rem;
}
.c-split-block--img-center .c-split-block__image img {
  object-position: center;
}
.c-split-block__title {
  grid-area: title;
  margin: 0;
  padding: 1rem 2rem;
}
.c-split-block__divider {
  border-top: 2px dashed #a1a199;
  grid-area: divider;
  margin: 0.5rem 0;
  margin-inline: unset;
}
.c-split-block__text {
  grid-area: content;
  align-self: center;
  padding: 1rem 2rem;
}
.c-split-block__text p {
  margin: 0;
}
.c-split-block__text > p + p {
  margin-top: 1rem;
}
.c-split-block__image, .c-split-block__image2 {
  --bg-image: #636355;
  position: relative;
  z-index: 100;
  grid-area: image;
  justify-self: left;
  align-self: center;
  background: var(--bg-image);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  min-height: 24rem;
  max-height: 30rem;
  max-width: 50rem;
}
.c-split-block__image img, .c-split-block__image2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
  position: absolute;
  display: block;
}
.c-split-block__image2 {
  grid-area: image2;
  justify-self: right;
}

@media (max-width: 1300px) {
  .c-split-block {
    grid-template-columns: 1fr 34rem 34rem 1fr;
  }
}
@media (max-width: 980px) {
  .c-split-block {
    display: block;
  }
  .c-split-block__image, .c-split-block__image2 {
    display: none;
  }
}
.c-product-list {
  --row-count: 3;
  --column-count: 1;
  display: grid;
  grid-template-columns: 1fr minmax(auto, 70rem) 1fr;
  grid-template-rows: auto auto auto 1fr;
  grid-template-areas: "gutter1 title gutter2" "gutter1 content gutter2" "divider divider gutter2" "gutter3 products gutter2";
}
.c-product-list--tan-bg {
  background: #f5f1e9;
}
.c-product-list__title {
  grid-area: title;
  padding: 1rem 2rem;
  margin: 0;
}
.c-product-list__divider {
  border-top: 2px dashed #a1a199;
  grid-area: divider;
  margin: 0.5rem 0;
  margin-inline: unset;
}
.c-product-list__text {
  grid-area: content;
  padding: 1rem 2rem;
}
.c-product-list__text p {
  margin: 0;
}
.c-product-list__text > * + * {
  margin-top: 1rem;
}
.c-product-list__products {
  grid-area: products;
  display: grid;
  position: relative;
  z-index: 300;
  grid-template-columns: minmax(calc(25% -  0.5rem ), 1fr);
  grid-template-rows: repeat(var(--row-count), auto) auto;
  grid-auto-columns: minmax(calc(25% -  0.5rem ), 1fr);
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  grid-auto-flow: column;
  padding: 1rem 0;
  padding-bottom: 0;
  margin: 0 2rem;
  margin-bottom: 2rem;
  margin-bottom: -1.5rem;
  text-align: center;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  filter: drop-shadow(0 4px 8px rgba(0, 0, 0, 0.1));
  scrollbar-width: 0;
}
.c-product-list__products > * {
  display: contents;
}
.c-product-list__products > * > .snap {
  scroll-snap-align: start;
}
.c-product-list__products.can-scroll-left {
  mask-image: linear-gradient(to left, black 96.5%, rgba(0, 0, 0, 0.25));
  -webkit-mask-image: linear-gradient(to left, black 96.5%, rgba(0, 0, 0, 0.25));
}
.c-product-list__products.can-scroll-right {
  mask-image: linear-gradient(to right, black 96.5%, rgba(0, 0, 0, 0.25));
  -webkit-mask-image: linear-gradient(to right, black 96.5%, rgba(0, 0, 0, 0.25));
}
.c-product-list__products.can-scroll-left.can-scroll-right {
  mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.25), black 3.5%, black 97.5%, rgba(0, 0, 0, 0.25));
  -webkit-mask-image: linear-gradient(to left, rgba(0, 0, 0, 0.25), black 3.5%, black 97.5%, rgba(0, 0, 0, 0.25));
}
.c-product-list__products::-webkit-scrollbar {
  height: 0;
}
.c-product-list__controls {
  grid-row-start: 4;
  grid-column-start: 2;
  position: relative;
  height: 100%;
}
.c-product-list__controls > .prev,
.c-product-list__controls > .next {
  display: block;
  height: 4rem;
  width: 4rem;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}
.c-product-list__controls > .prev svg,
.c-product-list__controls > .next svg {
  height: 100%;
  width: 100%;
}
.c-product-list__controls > .prev.disabled,
.c-product-list__controls > .next.disabled {
  opacity: 0.15;
}
.c-product-list__controls > .prev:not(.disabled):hover > svg,
.c-product-list__controls > .next:not(.disabled):hover > svg {
  scale: 1.25;
}
.c-product-list__controls > .prev:not(.disabled):focus,
.c-product-list__controls > .next:not(.disabled):focus {
  background: #F4DC00;
  border-radius: 100%;
}
.c-product-list__controls > .prev {
  position: absolute;
  left: -3rem;
  top: calc(50% - 2rem);
}
.c-product-list__controls > .next {
  position: absolute;
  right: -3rem;
  top: calc(50% - 2rem);
}
.c-product-list__addons-left, .c-product-list__addons-right {
  position: relative;
  max-width: 15rem;
  width: 100%;
  pointer-events: none;
}
.c-product-list__addons-left {
  grid-area: gutter1;
  justify-self: left;
  margin: -2rem 0;
}
.c-product-list__addons-right {
  grid-area: gutter2;
  justify-self: right;
  margin: -2rem 0;
}

@media (max-width: 1300px) {
  .c-product-list__addons-left, .c-product-list__addons-right {
    display: none;
  }
  .c-product-list__products {
    grid-template-columns: minmax(calc((100%/3) -  0.5rem ), 1fr);
    grid-auto-columns: minmax(calc((100%/3) -  0.5rem ), 1fr);
  }
}
@supports not (display: contents) {
  .c-product-list {
    display: block;
    max-width: 70rem;
    margin: 0 auto;
    background: transparent !important;
  }
  .c-product-list__products {
    display: flex;
  }
  .c-product-list__products > * {
    display: flex;
    flex-basis: 33%;
    flex-direction: column;
    margin: 0 0.5rem;
  }
  .c-product-list__products > * > *:nth-child(2) {
    flex-grow: 1;
  }
  .c-product-list__products .c-product__text {
    background: #f5f1e9;
  }
}
@media (max-width: 980px) {
  .c-product-list {
    display: block;
    position: relative;
    padding-bottom: 2rem;
  }
  .c-product-list__controls {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 2000;
    pointer-events: none;
  }
  .c-product-list__controls > * {
    pointer-events: auto;
  }
  .c-product-list__controls > .prev {
    left: 0;
  }
  .c-product-list__controls > .next {
    right: 0;
  }
  .c-product-list__products {
    margin: 0 2rem;
    grid-template-columns: minmax(85%, 1fr);
    grid-auto-columns: minmax(85%, 1fr);
  }
}
.c-product > * {
  padding: 1rem;
  background: white;
}
.c-product > * > * {
  margin: 0;
}
.c-product > * > * + * {
  margin-top: 1rem;
}
.c-product__title, .c-product__accent {
  background: #636355;
  color: white;
  font-weight: 600;
}
.c-product__title {
  font-size: 1.25rem;
  font-weight: 700;
  color: #F4DC00;
}
.c-product--expanded .c-product__text {
  grid-row-end: span 2;
  background: #636355;
  color: white;
}

.c-header {
  background: #636355;
  color: white;
  padding: 0;
  height: 6rem;
}
.c-header__container {
  display: flex;
  align-items: center;
}
.c-header .logo, .c-header__phone, .c-header__main-site {
  display: inline-block;
  vertical-align: top;
  line-height: 4rem;
  max-height: 4rem;
}
.c-header .logo {
  max-height: 4rem;
  margin: 1rem 0;
}
.c-header .logo > svg {
  max-height: 4rem;
  max-width: 11rem;
}
.c-header .logo--small {
  display: none;
}
.c-header__divider {
  display: inline-block;
  height: 6rem;
  width: 2px;
  margin: 0 2rem;
  background: #a1a199;
  transform: skew(-24deg);
}
.c-header__phone, .c-header__main-site {
  font-size: 1.4rem;
  margin: 1rem 0;
  font-weight: 600;
}
.c-header__phone > svg, .c-header__main-site > svg {
  max-height: 2rem;
  max-width: 2rem;
  line-height: 2rem;
  margin: 1rem 0;
  margin-right: 0.5rem;
  vertical-align: top;
}
.c-header__phone .button, .c-header__main-site .button {
  background: #F4DC00;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  text-decoration: none;
}
.c-header__phone > .main-menu__btn-sm, .c-header__main-site > .main-menu__btn-sm {
  display: none;
}
.c-header__phone {
  flex-grow: 1;
}
.c-header__i18n-links {
  display: inline-block;
  margin-left: auto;
}
.c-header__i18n-links > * {
  display: block;
  line-height: 1.5rem;
  text-decoration: none;
  color: white;
}
.c-header__i18n-links > * > * {
  vertical-align: top;
}
.c-header__i18n-links > a {
  color: #F4DC00;
}
.c-header__i18n-links > a > svg {
  margin-right: 0.25rem;
}
.c-header__i18n-links .c-header__i18n-link-text-abbr {
  display: none;
}
.c-header__main-site {
  position: relative;
  z-index: 1000;
  line-height: 2.5rem;
}

@media (max-width: 980px) {
  .c-header {
    height: 4rem;
  }
  .c-header .logo {
    max-height: 3rem;
    margin: 0.5rem 0;
    padding-right: 0.5rem;
  }
  .c-header .logo > svg {
    max-height: 3rem;
    max-width: 8rem;
  }
  .c-header__divider {
    height: 4rem;
    margin: 0 1rem;
  }
  .c-header__phone {
    font-size: 1rem;
    margin: 0.5rem 0;
    line-height: 3rem;
  }
  .c-header__phone > svg {
    display: none;
  }
  .c-header__main-site {
    margin: 0;
  }
  .c-header__i18n-links > * {
    display: inline-block;
  }
  .c-header__i18n-links .c-header__i18n-caption,
.c-header__i18n-links .c-header__i18n-link-text-full {
    display: none;
  }
  .c-header__i18n-links .c-header__i18n-link-text-abbr {
    display: inline-block;
  }
}
@media (max-width: 768px) {
  .c-header__phone, .c-header__main-site {
    line-height: 2rem;
    margin: 0.5rem 0;
  }
  .c-header__main-site {
    font-size: 0;
  }
  .c-header__main-site > .button {
    padding: 0.5rem;
    line-height: 1rem;
  }
  .c-header__main-site .main-menu__btn-lg {
    display: none;
  }
  .c-header__main-site .main-menu__btn-sm {
    display: inline-block;
  }
  .c-header__i18n-links .c-header__i18n-link-text-abbr {
    display: none;
  }
}
@media (max-width: 600px) {
  .c-header {
    height: 3.5rem;
  }
  .c-header .logo {
    max-height: 2rem;
  }
  .c-header .logo > svg {
    vertical-align: top;
    max-height: 2rem;
    max-width: 5rem;
  }
  .c-header__divider {
    display: none;
  }
  .c-header > * > * + * {
    margin-left: 0.75rem;
  }
}
@media (max-width: 500px) {
  .c-header {
    height: 3rem;
    /*.logo {
    	display: none;
    }*/
  }
  .c-header .logo--full {
    display: none;
  }
  .c-header .logo--small {
    display: block;
  }
  .c-header .logo--small > svg {
    display: block;
    height: 2rem;
    width: 2rem;
  }
  .c-header__container {
    padding: 0 1rem !important;
  }
  .c-header__phone {
    margin-left: 0 !important;
  }
}
.c-footer {
  position: relative;
  padding: 2rem 0;
  margin-top: 4rem;
  z-index: 100;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: black;
  background: #f5f1e9;
}
.c-footer__inner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  max-width: 70rem;
  margin: 0 auto;
  padding: 0 2rem;
}
.c-footer .controls {
  display: flex;
  max-width: 70rem;
  margin: 0 auto;
  margin-top: 0.75rem;
  padding: 0 2rem;
  padding-top: 0.75rem;
  border-top: 1px solid #c1c1bb;
}
.c-footer .controls > * {
  padding: 0 1rem;
}
.c-footer .controls > *:first-child {
  padding-left: 0;
}
.c-footer .controls > *:last-child {
  padding-right: 0;
}
.c-footer .controls__button {
  display: inline-block;
  text-decoration: none;
  margin-left: 0.5rem;
}
.c-footer .controls__link {
  display: inline-block;
  text-decoration: underline;
  padding-left: 0.25rem;
}
.c-footer .controls svg {
  line-height: 1.5rem;
  vertical-align: middle;
}

@media (max-width: 980px) {
  .c-footer__inner {
    display: block;
  }
  .c-footer__inner .col + .col {
    margin-top: 1.5rem;
  }
}
.c-feature-list {
  display: grid;
  grid-template-columns: 1fr minmax(auto, 70rem) 1fr;
  grid-template-rows: auto auto auto;
  grid-template-areas: "gutter1 title   gutter2" "gutter1 divider divider" "gutter1 content gutter3";
}
.c-feature-list__title {
  grid-area: title;
  margin: 0;
  padding: 1rem 2rem;
}
.c-feature-list__divider {
  border-top: 2px dashed #a1a199;
  grid-area: divider;
  margin: 0.5rem 0;
  margin-inline: unset;
  /* Overwrite default <hr> behavior */
}
.c-feature-list__stripe {
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-start: gutter3;
  align-self: center;
  background: #f5f1e9;
  height: 4rem;
  width: 100%;
}
.c-feature-list__container {
  grid-area: content;
  display: grid;
  z-index: 100;
  padding: 1rem 0;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: 2rem;
}

@media (max-width: 600px) {
  .c-feature-list {
    display: block;
  }
  .c-feature-list__stripe {
    display: none;
  }
  .c-feature-list__container {
    display: block;
  }
  .c-feature-list__container > * + * {
    margin-top: 2rem;
  }
}
.c-feature {
  position: relative;
  padding: 1rem 2rem;
  margin: 0;
  text-align: center;
}
.c-feature > * {
  position: relative;
  z-index: 100;
}
.c-feature--lozenge-bg::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: white;
  transform: skew(-24deg);
}
.c-feature__title {
  font-weight: 700;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.c-feature__icon {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  margin-bottom: 1rem;
}
.c-feature__icon > svg {
  width: 100%;
  height: auto;
}

.c-heading {
  font-size: 1.75rem;
  line-height: 2rem;
}
.c-heading__subtitle {
  font-weight: 400;
}
.c-heading__subtitle::before {
  content: " – ";
}
.c-heading__icon {
  vertical-align: middle;
  height: 5rem;
  margin: -1.75rem 0;
  margin-top: -2.5rem;
}

.c-addons {
  position: absolute;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.c-addons > * {
  position: absolute;
}
.c-addons > .addon-top {
  top: 0;
}
.c-addons > .addon-bottom {
  bottom: 0;
}
.c-addons > .addon-right {
  right: 0;
}
.c-addons > .addon-left {
  left: 0;
}
.c-addons > .addon-scale-vertical {
  height: 100%;
  width: auto;
}
.c-addons > .addon-scale-horizontal {
  width: 100%;
}
.c-addons > .nudge-left-sm {
  transform: translateX(-2rem);
}
.c-addons > .nudge-left-md {
  transform: translateX(-35%);
}
.c-addons > .nudge-left-lg {
  transform: translateX(-50%);
}
.c-addons > .nudge-right-sm {
  transform: translateX(2rem);
}
.c-addons > .nudge-right-md {
  transform: translateX(35%);
}
.c-addons > .nudge-right-lg {
  transform: translateX(50%);
}

.c-button {
  position: relative;
  display: inline-block;
  padding: 0.5rem;
  color: #636355;
  background: #F4DC00;
  font: 16px/24px museo-sans, Verdana, Geneva, sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  text-decoration: none;
  font-weight: 700;
  border: none;
  overflow: hidden;
  vertical-align: top;
  cursor: pointer;
}
.c-button > * {
  position: relative;
  z-index: 100;
  -webkit-font-smoothing: antialiased;
}
.c-button--angled {
  padding: 0.5rem 1.5rem;
  transform: skew(-24deg);
}
.c-button--angled > * {
  display: inline-block;
  transform: skew(24deg);
}
.c-button--lg {
  padding: 1rem 2rem;
}
.c-button::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #ffef5b;
  transition: transform 0.35s;
  transform: skew(48deg) translateX(-135%);
}
.c-button:hover {
  color: #636355;
}
.c-button:hover::after {
  transform: skew(48deg) translateX(135%);
}

.c-introduction h1, .c-introduction h2, .c-introduction h3 {
  margin-bottom: 0;
}
.c-introduction h1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 900;
}
.c-introduction h2 {
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-weight: 900;
}
.c-introduction h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
}

.c-divider {
  position: relative;
  border: none;
  border-top: 2px dashed #a1a199;
  margin: 1.5rem -1rem;
  overflow: visible;
}
.c-divider::after {
  content: "";
  position: absolute;
  left: 2rem;
  top: 0.5rem;
  width: 50%;
  border-top: 3px solid #e9bc16;
}
.c-divider--compact {
  width: 50%;
  margin: 1.5rem auto;
}
.c-divider--compact::after {
  left: 20%;
  right: 20%;
  width: auto;
}

.c-sector-list__title {
  font-size: 2.25rem;
  line-height: 4rem;
  vertical-align: top;
  font-weight: 900;
}
.c-sector-list__title > svg {
  display: inline-block;
  vertical-align: top;
}
.c-sector-list__sector {
  position: relative;
  margin-top: 2rem;
  background: #f5f1e9;
}
.c-sector-list__sector--has-image {
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-column-gap: 1rem;
}
.c-sector-list__sector-content {
  padding: 1rem;
}
.c-sector-list__sector-button {
  position: absolute;
  right: 2rem;
  bottom: -1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  z-index: 1000;
  cursor: pointer;
}
.c-sector-list__sector img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 1300px) {
  .c-sector-list .u-clip::before,
.c-sector-list .u-clip::after {
    display: none;
  }
  .c-sector-list__sector {
    display: block;
  }
  .c-sector-list__sector img {
    display: none;
  }
}
.c-hero-list {
  font-size: 1.125rem;
  list-style: none;
  padding-left: 0;
}
.c-hero-list > li {
  padding: 0.25rem 0;
}
.c-hero-list > li::before {
  content: "•";
  display: inline-block;
  padding: 0 0.5rem;
  font-weight: 900;
  color: #e9bc16;
}
.c-hero-list > li > em {
  font-weight: 700;
  font-style: normal;
}

.c-black-friday h2 {
  font-size: 2rem;
  line-height: 2rem;
  font-weight: 900;
}
.c-black-friday h3 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 300;
  padding-top: 1.5rem;
}
.c-black-friday h3 em {
  font-weight: 700;
  box-shadow: inset 0 -0.05em 0 #fff, inset 0 -0.4em 0 #f4dc00;
}
.c-black-friday section {
  margin-top: 2rem;
}
.c-black-friday__conditions {
  font-size: 0.8rem;
  line-height: 1.25rem;
  list-style: none;
  padding: 0;
  color: #828277;
}
.c-black-friday__conditions > li {
  margin: 0;
}
.c-black-friday__conditions > li::before {
  content: "* ";
  color: #F4DC00;
}
.c-black-friday__conditions > li + li {
  margin-top: 0.25rem;
}
@media (max-width: 700px) {
  .c-black-friday .c-heading svg {
    display: none;
  }
}
.c-black-friday .c-table-wrapper {
  overflow-x: auto;
}
.c-black-friday .c-table-wrapper > .c-table {
  min-width: 500px;
}
@media (max-width: 700px) {
  .c-black-friday .c-table-wrapper {
    border: 2px solid #e0e0dd;
    margin-left: -1rem;
    margin-right: -1rem;
  }
}

@keyframes bf-heading-fade-in {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.c-header-black-friday {
  background: black url("../img/banner_black_friday.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: right;
  color: white;
  padding: 3rem 0;
}
.c-header-black-friday__heading1 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: white;
}
.c-header-black-friday__heading2 {
  font-size: 3rem;
  line-height: 3rem;
  font-weight: 300;
  margin: 0;
  padding: 0;
  color: white;
}
.c-header-black-friday__subtitle {
  font-size: 1.5rem;
  line-height: 1.5rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
  color: #e9bc16;
}
.c-header-black-friday__big-deals {
  font-size: 8rem;
  line-height: 8rem;
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: #e9bc16;
}
.c-header-black-friday__conditions {
  font-style: italic;
}
.c-header-black-friday__title > * + * {
  margin-top: 0.5rem;
}
.c-header-black-friday__title > * {
  animation-name: bf-heading-fade-in;
  animation-duration: 0.5s;
  animation-fill-mode: both;
}
.c-header-black-friday__title > :nth-child(2) {
  animation-delay: 0.3s;
}
.c-header-black-friday__title > :nth-child(3) {
  animation-delay: 0.45s;
}
.c-header-black-friday__title > :nth-child(4) {
  animation-delay: 0.6s;
}
.c-header-black-friday__title > :nth-child(5) {
  animation-delay: 0.75s;
}
.c-header-black-friday__title > :nth-child(6) {
  animation-delay: 0.9s;
}
@media (max-width: 600px) {
  .c-header-black-friday__heading1, .c-header-black-friday__heading2 {
    font-size: 2rem;
    line-height: 2rem;
  }
  .c-header-black-friday__big-deals {
    font-size: 5rem;
    line-height: 5rem;
  }
}

@keyframes bounce-in {
  0% {
    transform: translateY(var(--offset)) scale(1) rotate(0deg);
  }
  33% {
    transform: translateY(var(--offset)) scale(1.025) rotate(1deg);
  }
  66% {
    transform: translateY(var(--offset)) scale(1.025) rotate(-1deg);
  }
  100% {
    transform: translateY(var(--offset)) scale(1) rotate(0deg);
  }
}
.c-form-black-friday {
  position: relative;
  top: -10rem;
  padding: 1rem;
  background: #f5f1e9;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(var(--offset)) scale(1) rotate(0deg);
}
.c-form-black-friday--wiggle {
  animation-name: bounce-in;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
}
.c-form-black-friday:focus-within {
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
  outline: 2px solid #e9bc16;
}
@media (max-width: 70rem) {
  .c-form-black-friday {
    top: 0;
    margin-top: 2rem;
    box-shadow: none;
  }
}
.c-form-black-friday h2 {
  font-weight: 900;
  text-align: center;
  margin-bottom: 0;
}
.c-form-black-friday .form-block {
  padding: 0;
  background: none;
}

.c-product-search {
  margin: 1.5rem 0;
}
.c-product-search > * + * {
  margin-top: 1rem;
}
.c-product-search__form {
  display: flex;
}
.c-product-search__form > * {
  flex-grow: 1;
}
.c-product-search__results > * + * {
  margin-top: 1rem;
}
.c-product-search__show-more {
  display: flex;
  justify-content: center;
  padding: 1rem;
}
@media (max-width: 1200px) {
  .c-product-search__form {
    flex-wrap: wrap;
  }
  .c-product-search__results {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;
  }
  .c-product-search__results > * + * {
    margin-top: 0;
  }
}
@media (max-width: 768px) {
  .c-product-search__results {
    display: block;
  }
  .c-product-search__results > * + * {
    margin-top: 1rem;
  }
}

.c-product-search-block {
  display: grid;
  grid-template-columns: 2fr auto 5fr auto 2fr;
  grid-template-rows: 6rem;
  background: white;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.c-product-search-block > * {
  padding: 0.5rem 1rem;
}
.c-product-search-block > .c-angle-trim {
  padding: 0;
}
.c-product-search-block__title, .c-product-search-block__cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.c-product-search-block__title {
  align-items: flex-start;
  background: #636355;
  color: #F4DC00;
  border-left: 6px solid #F4DC00;
}
.c-product-search-block__title > * {
  color: #F4DC00;
}
.c-product-search-block__title h4 {
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: bold;
}
.c-product-search-block__title span {
  font-size: 1.125rem;
  font-weight: bold;
}
.c-product-search-block__price span {
  color: white;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
}
.c-product-search-block__specs {
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
}
.c-product-search-block__spec-entry {
  line-height: 1.5rem;
}
.c-product-search-block__spec-entry > *:nth-child(1) {
  min-width: 4rem;
  display: inline-block;
}
.c-product-search-block__spec-entry > *:nth-child(2) {
  font-weight: bold;
  color: black;
}
.c-product-search-block__cta {
  align-items: center;
}
@media (max-width: 1200px) {
  .c-product-search-block {
    display: block;
  }
  .c-product-search-block > .c-angle-trim {
    display: none;
  }
}

.selling-point {
  display: grid;
  grid-template-columns: 4.5rem 1fr;
  grid-column-gap: 2rem;
  margin-top: 2rem;
}
.selling-point > .image {
  width: 100%;
  max-width: 4.5rem;
}
.selling-point > .content > * {
  transform-origin: right;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(15%);
  }
}
.form-block {
  position: relative;
  max-width: 24rem;
  padding: 2rem;
}
.form-block > .input-group {
  position: relative;
}
.form-block > .input-group > input,
.form-block > .input-group > textarea {
  display: block;
  width: 100%;
  margin: 0;
  padding: 1rem;
  font: 16px/24px "museo-sans", Verdana, Geneva, sans-serif;
  background: white;
  border: 1px solid #e0e0dd;
}
.form-block > .input-group > input:focus,
.form-block > .input-group > textarea:focus {
  outline: 1px solid #F4DC00;
  border-color: #F4DC00;
}
.form-block > .input-group > textarea {
  min-width: 100%;
  max-width: 100%;
  min-height: 6rem;
}
.form-block > .input-group > label {
  display: inline;
  position: absolute;
  left: 0.5rem;
  top: 1rem;
  padding: 0 0.5rem;
  color: #636355;
  background: white;
  transform: translateY(0%) scale(1);
  transform-origin: left;
  transition: transform 0.2s, opacity 0.2s;
  opacity: 0.9;
}
.form-block > .input-group.active > label {
  transform: translateY(-110%) scale(0.75);
  opacity: 1;
}
.form-block > .input-group + .input-group {
  margin-top: 1rem;
}
.form-block .checkbox-group {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1rem;
  padding: 1rem 0;
}
.form-block .checkbox-group > .checkbox {
  display: block;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #e0e0dd;
}
.form-block .checkbox-group > .checkbox > input {
  display: block;
  position: absolute;
  left: -2px;
  right: 0;
  top: -2px;
  bottom: 0;
  width: 1.5rem;
  height: 1.5rem;
  z-index: 10;
  opacity: 0;
  cursor: pointer;
}
.form-block .checkbox-group > .checkbox .tick-mark {
  display: block;
  position: absolute;
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
  z-index: 1;
  text-align: center;
  background: #F4DC00;
  transform: scale(0);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s;
}
.form-block .checkbox-group > .checkbox > input:checked ~ .tick-mark {
  transform: scale(1);
  opacity: 1;
}
.form-block .checkbox-group > .checkbox:focus-within {
  border-color: #F4DC00;
}
.form-block .checkbox-group > label {
  font-size: 0.8rem;
  line-height: 1.25rem;
  cursor: pointer;
}
.form-block button {
  display: block;
  position: absolute;
  width: calc(100% - 4rem);
  bottom: -1.5rem;
  left: 2rem;
  border: none;
  background: #F4DC00;
  color: #636355;
  font: 16px/24px "museo-sans", Verdana, Geneva, sans-serif;
  font-weight: 700;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
}
.form-block .overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 2rem;
  padding-bottom: 1rem;
  background: white;
  z-index: 100;
}
.form-block .overlay.gdpr-checkbox {
  padding-top: 5rem;
  bottom: 40%;
  background: linear-gradient(to bottom, white, white 60%, rgba(255, 255, 255, 0) 100%);
}
.form-block .overlay > h3 {
  text-align: center;
  font-weight: 500;
  font-size: 1.5rem;
}
.form-block .overlay > .caption {
  text-align: center;
}
.form-block .overlay .load-spinner {
  width: 100%;
  animation: spin 3s linear infinite;
}
.form-block .overlay .form-arrow {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  animation: bounce 1s ease-in-out alternate infinite;
}
.form-block .overlay ul {
  text-align: left;
  padding: 0;
  padding-left: 1rem;
}

.u-anchor-top {
  align-self: start;
}

.u-anchor-bottom {
  align-self: end;
}

.u-anchor-left {
  justify-self: left;
}

.u-anchor-right {
  justify-self: right;
}

.u-anchor-center {
  justify-self: center;
}

.u-anchor-center-vert {
  align-self: center;
}

.l-row--tan {
  background: #f5f1e9;
}
.l-row__inner,
.l-row > * {
  max-width: 70rem;
  margin: 0 auto;
  padding: 0 2rem;
}

.l-stack > * + * {
  margin-top: 3rem;
}
.l-stack--compact > * + * {
  margin-top: 1.5rem;
}

.l-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
@media (max-width: 70rem) {
  .l-grid--collapse-sm {
    display: block;
  }
}

.u-clip {
  position: relative;
}
.u-clip--start, .u-clip--end {
  position: relative;
}
.u-clip--start::before, .u-clip--start-striped::before, .u-clip--start-border::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: inline("svg/angled-clip-start.svg");
  background-repeat: no-repeat;
  background-position-x: 0%;
  background-size: 100% 100%;
  z-index: 10;
}
.u-clip--start-striped::before {
  background-image: inline("svg/angled-clip-start-stripe.svg");
}
.u-clip--start-border::before {
  background-image: inline("svg/angled-clip-start-border.svg");
  background-size: cover;
}
.u-clip--end::after, .u-clip--end-border::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: inline("svg/angled-clip-end.svg");
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-size: cover;
  z-index: 10;
}
.u-clip--end-border::before {
  background-image: inline("svg/angled-clip-end-border.svg");
}

.u-affix {
  --offset: 0px;
  transform: translateY(var(--offset));
}
@media (max-width: 70rem) {
  .u-affix {
    transform: unset;
  }
}

*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

.form-block {
  max-width: unset;
  padding: 1rem 0;
}
.form-block button {
  position: static;
  margin: 0;
  width: 100%;
  left: 0;
  bottom: 0;
}

.c-introduction .c-split-block__title {
  z-index: 300;
  margin-right: -12rem;
}

@media (max-width: 980px) {
  .c-introduction .c-split-block__title {
    margin-right: 0 !important;
  }
}