// Hacked together in preparation for a Black Friday special on short notice.
// This may need to be updated and improved later.

.c-black-friday {

	h2 {
		font-size: 2rem;
		line-height: 2rem;
		font-weight: 900;
	}

	h3 {
		font-size: 1.75rem;
		line-height: 1.75rem;
		font-weight: 300;

		padding-top: 1.5rem;

		em {
			font-weight: 700;
			// The base style doesn't quite work here, overriding a few bits
			box-shadow: inset 0 -0.05em 0 #fff, inset 0 -0.4em 0 #f4dc00;
		}
	}

	section {
		margin-top: 2rem;
	}

	&__conditions {
		font-size: 0.8rem;
		line-height: 1.25rem;
		list-style: none;
		padding: 0;

		color: $color-gray-60;

		> li {
			margin: 0;

			&::before {
				content: '* ';

				color: $color-primary;
			}
		}

		> li + li {
			margin-top: 0.25rem;
		}
	}


	// Stuff from here is blatant hacks, to get this ready to go live before the deadline

	// SVG icons result in weird text wrapping when headings are more than one line
	.c-heading {
		@media(max-width: 700px) {
			svg {
				display: none;
			}
		}
	}

	.c-table-wrapper {
		overflow-x: auto;

		> .c-table {
			min-width: 500px; // this is around when content starts to break;
		}

		@media(max-width: 700px) {
			border: 2px solid $color-gray-90;
			margin-left: -1rem;
			margin-right: -1rem;
		}
	}

}
