
.c-heading {

	font-size: 1.75rem;
	line-height: 2rem;

	&__subtitle {
		font-weight: 400;

		&::before {
			content: ' – ';
		}
	}

	&__icon {
		vertical-align: middle;
		height: 5rem;
		// Toss some values in to get a roughly sane vertical alignment
		margin: -1.75rem 0;
		margin-top: -2.5rem;
	}

}
