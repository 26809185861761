@use '../m247-css/src/scss/components/c-radio-button';
@use '../m247-css/src/scss/components/c-form-field';
@use '../m247-css/src/scss/components/c-table';
@use '../m247-css/src/scss/layout/l-flex';

@import 'variables';

@import 'ie-fixes';

@import 'typography';
@import 'overrides';

@import 'elements/table';

@import 'components/c-split-block';
@import 'components/c-product-list';
@import 'components/c-product';
@import 'components/c-header';
@import 'components/c-footer';
@import 'components/c-feature-list';
@import 'components/c-feature';
@import 'components/c-heading';
@import 'components/c-addons';
@import 'components/c-button';
@import 'components/c-introduction';
@import 'components/c-divider';
@import 'components/c-sector-list';
@import 'components/c-hero-list';
@import 'components/c-black-friday';
@import 'components/c-header-black-friday';
@import 'components/c-form-black-friday';
@import 'components/c-product-search';
@import 'components/c-product-search-block';

@import 'components/selling-points';
@import 'components/form-block';

@import 'components/u-anchoring';
@import 'components/l-row';
@import 'components/l-stack';
@import 'components/l-grid';
@import 'components/u-clip';
@import 'components/u-affix';

*, *::before, *::after {
	box-sizing: border-box;
}

html,body {
	margin: 0;
	padding: 0;
	overflow-x: hidden;
	margin: 0;
	padding: 0;
}

// Overwrite some styling for the form block from the original landing pages
.form-block {
	max-width: unset;
	padding: 1rem 0;

	// This one is positioned fairly creatively to overlap the bottom of the form,
	// in the original.  It's been updated to just display normally
	button {
		position: static;

		margin: 0;
		width: 100%;
		left: 0;
		bottom: 0;
	}
}

// A special-case override to extend the title into the free whitespace, on desktop
.c-introduction .c-split-block__title {
	z-index: 300;
	margin-right: -12rem;
}

@media( max-width: $breakpoint-mobile ) {
	.c-introduction .c-split-block__title {
		margin-right: 0 !important;
	}
}