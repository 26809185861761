
@keyframes spin { 100% { transform: rotate(360deg); } }
@keyframes bounce {
	0% {
		transform: translateY(0%);
	}

	100% {
		transform: translateY(15%);
	}
}

.form-block {
	position: relative;

	max-width: 24rem;

	padding: 2rem;

	> .input-group {

		position: relative;

		> input,
		> textarea {
			display: block;

			width: 100%;

			margin: 0;
			padding: 1rem;

			font: 16px/24px 'museo-sans', Verdana, Geneva, sans-serif;

			background: white;
			border: 1px solid $color-gray-90;

			&:focus {
				// Fake a 2px yellow outline without causing any reflow weirdness
				outline: 1px solid $color-primary;
				border-color: $color-primary;
			}
		}

		> textarea {
			min-width: 100%;
			max-width: 100%;

			min-height: 6rem;
		}

		> label {
			display: inline;
			position: absolute;

			left: 0.5rem;
			top: 1rem;

			padding: 0 0.5rem;

			color: $color-gray-50;
			background: white;

			transform: translateY(0%) scale(1);
			transform-origin: left;
			transition: transform 0.2s, opacity 0.2s;

			opacity: 0.9;
		}

		&.active > label {
			transform: translateY(-110%) scale(0.75);
			opacity: 1;
		}

		& + .input-group {
			margin-top: 1rem;
		}
	}

	.checkbox-group {
		display: grid;

		grid-template-columns: auto 1fr;
		grid-column-gap: 1rem;

		padding: 1rem 0;

		> .checkbox {
			display: block;

			position: relative;

			width: 1.5rem;
			height: 1.5rem;

			border: 2px solid $color-gray-90;

			> input {
				display: block;

				position: absolute;

				left: -2px;
				right: 0;
				top: -2px;
				bottom: 0;

				width: 1.5rem;
				height: 1.5rem;

				z-index: +10;

				opacity: 0;

				cursor: pointer;
			}

			.tick-mark {
				display: block;

				position: absolute;
				left: -2px;
				right: -2px;
				top: -2px;
				bottom: -2px;

				z-index: +1;

				text-align: center;

				background: $color-primary;

				transform: scale(0);
				opacity: 0;

				transition: transform 0.2s, opacity 0.2s;
			}

			> input:checked ~ .tick-mark {
				transform: scale(1);
				opacity: 1;
			}

			&:focus-within {
				border-color: $color-primary;
			}
		}

		> label {
			font-size: 0.8rem;
			line-height: 1.25rem;

			cursor: pointer;
		}
	}

	button {
		display: block;
		position: absolute;

		width: calc(#{'100% - 4rem'});

		bottom: -1.5rem;
		left: 2rem;

		border: none;
		background: $color-primary;
		color: $color-gray-50;

		font: 16px/24px 'museo-sans', Verdana, Geneva, sans-serif;
		font-weight: 700;

		padding: 1rem;

		text-align: center;

		cursor: pointer;
	}

	.overlay {
		position: absolute;

		left: 0;
		right: 0;
		top: 0;
		bottom: 0;

		padding: 2rem;
		padding-bottom: 1rem;

		background: white;

		z-index: 100;

		&.gdpr-checkbox {
			padding-top: 5rem;
			bottom: 40%;
			background: linear-gradient(to bottom, white, white 60%, transparentize(white, 1) 100%);
		}

		> h3 {
			text-align: center;
			font-weight: 500;
			font-size: 1.5rem;
		}

		> .caption {
			text-align: center;
		}

		.load-spinner {
			width: 100%;
			animation: spin 3s linear infinite;
		}

		.form-arrow {
			position: absolute;

			width: 100%;
			bottom: 0;
			left: 0;
			right: 0;

			animation: bounce 1s ease-in-out alternate infinite;
		}

		ul {
			text-align: left;

			padding: 0;
			padding-left: 1rem;
		}
	}

}
