
// Museo Sans font-face rules
@font-face {
	font-family: 'museo-sans';
	src: url('/fonts/MuseoSans_300-webfont.woff2') format('woff2'),
		 url('/fonts/MuseoSans_300-webfont.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'museo-sans';
	src: url('/fonts/MuseoSans_500-webfont.woff2') format('woff2'),
		 url('/fonts/MuseoSans_500-webfont.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'museo-sans';
	src: url('/fonts/MuseoSans_700-webfont.woff2') format('woff2'),
		 url('/fonts/MuseoSans_700-webfont.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'museo-sans';
	src: url('/fonts/MuseoSans_900-webfont.woff2') format('woff2'),
		 url('/fonts/MuseoSans_900-webfont.woff') format('woff');
	font-weight: 900;
	font-style: normal;
}

h1,h2,h3,h4,h5,h6 {
	color: $color-gray-50;

	margin: 0;
	margin-bottom: 1rem;

	> em {
		font-style: inherit;
		font-weight: 700;

		box-shadow:
			inset 0 -0.1em 0 $body-color,
			inset 0 -0.35em 0 $color-primary;
	}
}

h2,h3,h4,h5,h6 {
	font-weight: 700;
	line-height: 2rem;

	> em {
		box-shadow:
			inset 0 -0.1em 0 $body-color,
			inset 0 -0.5em 0 $color-primary;
	}
}

h1 {
	font-weight: 400;

	font-size: 3rem;
	line-height: 3.5rem;

	margin-bottom: 1rem;
}

h2 { font-size: 1.8rem; }

h3 { font-size: 1.4rem; margin-bottom: 0.5rem; }

h4 { font-size: 1.3rem; margin-bottom: 0.5rem; }

h5 { font-size: 1.1rem; margin-bottom: 0.5rem; }

h6 { font-size: 1rem; margin-bottom: 0.25rem; }

html, body {
	font: 16px/24px 'museo-sans', Verdana, Geneva, sans-serif;

	color: $color-gray;
}

a,
a:visited,
a:active {
	color: $color-black;
	text-decoration: underline;

	&:hover {
		color: $color-primary-darker;
	}
}
