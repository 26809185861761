
.c-introduction {

	h1, h2, h3 {
		margin-bottom: 0;
	}

	h1 {
		font-size: 3rem;
		line-height: 3rem;
		font-weight: 900;
	}

	h2 {
		font-size: 2.25rem;
		line-height: 2.5rem;
		font-weight: 900;
	}

	h3 {
		font-size: 1.5rem;
		line-height: 2rem;
		font-weight: 400;
	}

}
