
.c-divider {
	position: relative;
	
	border: none;
	border-top: 2px dashed $color-gray-70;

	margin: 1.5rem -1rem;

	overflow: visible;

	&::after {
		content: '';

		position: absolute;
		left: 2rem;
		top: 0.5rem;

		width: 50%;

		border-top: 3px solid $color-primary-darker;
	}

	&--compact {
		width: 50%;
		margin: 1.5rem auto;

		&::after {
			left: 20%;
			right: 20%;

			width: auto;
		}
	}
}
