.l-stack {
	> * + * {
		margin-top: 3rem;
	}

	&--compact {
		> * + * {
			margin-top: 1.5rem;
		}
	}
}
