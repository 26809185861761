
.c-footer {
	position: relative;

	padding: 2rem 0;
	margin-top: 4rem;

	z-index: 100;

	font-size: 0.8rem;
	line-height: 1.5rem;
	color: black;

	background: $color-tan;

	&__inner {
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 4rem;

		max-width: $body-width;
		margin: 0 auto;

		padding: 0 $row-padding;
	}

	.controls {
		display: flex;

		max-width: $body-width;
		margin: 0 auto;

		margin-top: 0.75rem;
		padding: 0 $row-padding;
		padding-top: 0.75rem;

		border-top: 1px solid $color-gray-80;

		> * {
			padding: 0 1rem;
		}

		> *:first-child {
			padding-left: 0;
		}

		> *:last-child {
			padding-right: 0;
		}

		&__button {
			display: inline-block;
			text-decoration: none;
			margin-left: 0.5rem;
		}

		&__link {
			display: inline-block;
			text-decoration: underline;
			padding-left: 0.25rem;
		}

		svg {
			line-height: 1.5rem;
			vertical-align: middle;
		}
	}
}

@media( max-width: $breakpoint-mobile ) {

	.c-footer {
		&__inner {
			display: block;

			.col + .col {
				margin-top: 1.5rem;
			}
		}
	}

}