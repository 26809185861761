
.c-table {
	min-width: 100%;

	border-collapse: separate;

	&__cell {
		background: $color-tan;
		color: $color-gray-50;
		padding: 0.5rem;
		
		border: 2px solid white;

		&--callout,
		&--header {
			background: $color-gray-50;
			color: $color-white;
			font-weight: 700;
		}

		&--callout {
			text-align: center;
			color: $color-primary;
		}
	}
}
